.dashboard-table.general-managers {
    height: 80vh;
}

.dashboard-table.general-managers tbody {
    max-height: calc(80vh - 8em);
}

.dashboard-table.general-managers tbody button {
    color: #212529 !important
}
