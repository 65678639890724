


.grid-view {
  padding: 1rem;

}
.grid-view:not(.loading)
{
  height: auto !important;
}


.grid-view .item {
  margin-top: 1em;
  margin-bottom: 1em;
}

.grid-view .item .inner {
  background: #fff;
}

.grid-view .item .inner .details {
  padding: 0 1em;
}

.grid-view .item img {
  width: 100%;
}

.grid-view .item h3 {
  font-size: 1.2em;
  margin: 0;
  padding: 0.5em 0;
}

.grid-view .item table {
  width: 100%;
  padding: 0;
  margin: 0;
}

.grid-view .item table tr {
  padding: 0.5em 0;
  border-top: 1px solid var(--primary-border-color);

  font-weight: normal;
}

.grid-view .item table td {
  padding: 0.5em 0;
}
.grid-view .item table th:nth-child(2), .grid-view .item table td:nth-child(2) {
  text-align: right;
}
.grid-view .item h3 {
  position: relative;
}
.grid-view .item .property-status-icon {
  position: absolute;
  right:0.5rem;
  top:1rem;
  width: 0.6rem;
  height: 0.6rem;
  border-radius: 50%;

}

.grid-view .item .property-status-icon.available {
  background: var(--available-color);
}

.grid-view .item .property-status-icon.sold {
  background: var(--sold-color);
}
.grid-view .item .property-status-icon.reserved {
  background: var(--option-color);
}
.grid-view .item .property-status-icon.not_released {
  background: var(--not-released-color);
}