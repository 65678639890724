.facility {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.facility .main-title {
  position: fixed;
  left: 2.5em;
  top: 2.5em;
  color: #fff;
  z-index: 20;
}
.facility .main-title.dark {
  color : #222;
}

.facility .main-title .number {
  font-size: 2em;
  line-height: 0.8em;

}

.facility > .inner {
  position: absolute;
  left: 0;
  top: 50%;
  width: auto;
  transform: translateY(-50%);
}

.facility .main-image {
  object-fit: cover;
  width: 100vw;
  height: 100vh;
}






@media (max-aspect-ratio: 16/9) {

  .facility > .inner {
    position: absolute;
    left: 50%;
    top: 0;
    width: 177.7777vh;
    height: 100vh;
    transform: translateX(-50%);
  }








}



