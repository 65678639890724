.intro-video
{
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: white;
    z-index: 1000;
    transition: opacity 1s, visibility 1s;
}

.hide-intro
{
    opacity: 0;
    visibility: hidden;

}
video {
    position:absolute;
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    z-index: 3;

}

#skip-button
{

    color: var(--primary-color);
    border : 1px solid var(--primary-color);
    background-color: transparent;
    border-radius: 2em;
    padding: 1em 2em;
    font-weight: bold;
    position: fixed;
    left: calc(50% - 3rem);
    bottom: 2rem;
    min-width: 6rem;
    z-index: 4;

}