.preview-image {
    position:fixed;
    background: white;
    padding: 0.5rem;
    opacity: 0;
    transform: translate(-100%,calc(50vh - 125px));
    transition: all 0.5s;
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;

}
.image-title {
    margin-top:0.5rem;
    text-align: center;
}

.preview-image.show {
    transform: translate(0%,calc(50vh - 125px));
    opacity: 1;
}