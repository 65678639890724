.message-icon {
    display: inline-block;
    width: 10rem;
    height: 10rem;
    background-color: var(--primary-color);
    mask-repeat: no-repeat;
    mask-position: center;

}
.message-icon.check {
    mask-image: url(../img/icon-check.svg);
}

.message-icon.fail {
    mask-image: url(../img/icon-fail.svg);
}