.dashboard-table.sales-managers {
    height: 80vh;
}

.dashboard-table.sales-managers tbody {
    max-height: calc(80vh - 8em);
}

.best-sales-managers {
    position: relative;
    height: 80vh;
    overflow: hidden;
}

.best-sales-managers h3 {
 padding: 3em 0;
}

.best-sales-managers .number-box {
    position: absolute;
    width: 33%;
    height: calc(80vh - 10em);
    left: 50%;
    top: 10em;
    margin: 0;
}

.best-sales-managers .number-box .number,
.best-sales-managers .number-box .text {
    top: 47%;
}

.best-sales-managers .number-box:first-child {
    left: 0;
}

.best-sales-managers .number-box:nth-child(2) {
    left: 50%;
    transform: translateX(-50%);
    border: solid var(--table-border-color);
    border-width: 0 1px;
}

.best-sales-managers .number-box:last-child {
    left: auto;
    right: 0;
}

