.most-presented-clients {
    border-radius: 0.428571429em;
    background: #fff;
    padding: 1rem;
    height: 40vh;
    margin-bottom: 30px;
    overflow: hidden;
}

.most-presented-clients h3 {
    text-transform: uppercase;
    font-size: 1.2em;
    padding-left: 0.75rem;
    padding-bottom: 0.7rem;
    text-align: center;
    border-bottom: 1px solid var(--table-border-color);
}

.most-presented-clients .number-box {
    height: calc(40vh - 3.2em);
    background: #fff;
    margin-bottom: 0;
    border-radius: 0;
}

.most-presented-clients .number-box .number,
.most-presented-clients .number-box .text {
    top: 44%;
}

.most-presented-clients .number-box:nth-child(2) {
    border: solid var(--table-border-color);
    border-width: 0 1px; 
}

.most-presented-clients .number-box .text {
    width: 100%;
    text-align: center;
}



