section.primary.dashboard {
  padding-top: 7em;
}

section.primary.dashboard > .container {
  max-width: none;
  padding-left: 2.5em;
  padding-right: 2.5em;
}

section.primary.dashboard .close-button {
  top: 1.7em;
  right: 2em;
}

.dashboard-box {
  background: #fff;
  padding: 1rem;
  border-radius: 0.428571429em;
  margin-bottom: 30px;
  overflow: hidden;
}

.dashboard-box h3 {
  text-transform: uppercase;
  font-size: 1.2em;
  padding-left: 0.75rem;
  padding-bottom: 0.7rem;
  border-bottom: 1px solid var(--table-border-color);
  color : var(--dashboard-block-title-color);
}

section.primary.dashboard > .container.dashboard-header {
  color: var(--title-color);
  padding-bottom: 3em;

  position: fixed;
  height: 7em;
  width: calc(100vw - 5em);
  left: 0;
  top: 0;

}

.dashboard-header .user {
  font-size: 0.85em;
  font-weight: bold;
  text-transform: uppercase;
  padding-top: 1.9em;
}

.dashboard-header .user .name {
  float: left;
  margin: 0.29em 0.5em 0 0;
}

.dashboard-header .user .logout-button {
  float: left;
  width: 2em;
  height: 2em;
  background-color: var(--primary-color);
  mask-repeat: no-repeat;
  mask-position: center;
  mask-image: url('../../img/icon-logout.svg');
  mask-size: 1.1em;
  cursor: pointer;
}

.dashboard-header .user .edit-button {
  float: left;
  width: 2em;
  height: 2em;
  background-color: var(--primary-color);
  mask-repeat: no-repeat;
  mask-position: center;
  mask-image: url('../../img/icon-edit.svg');
  mask-size: 1.1em;
  cursor: pointer;
}

.dashboard-menu {
  text-align: right;
}

.dashboard-menu a {
  display: inline-block;
  text-transform: uppercase;
  font-size: 0.9em;
  color: #212529;
  margin: 0 0.7em;
  padding-top: 1.6em;
  border-top: 0.4em solid transparent;
}

.dashboard-menu a:last-child {
  margin-right: 0;
}

.dashboard-menu a.active {
  border-top-color: var(--primary-color);
}

section .close-button > div {
  width: 80%;
  left: 10%;
}

.dashboard .number-box {
  position: relative;
  background: #fff;
  height: calc(20vh - 15px);
  margin-bottom: 30px;
  border-radius: 0.428571429em;
  
  

}

.dashboard .number-box .number {
  position: absolute;
  left: 0;
  top: 46%;
  transform: translateY(-50%);

  font-family: jaf-bernina-sans-comp, sans-serif;
  font-weight: 400;
  font-style: normal;

  font-size: 5.5em;
  color: var(--dashboard-number-color);
  width: 100%;
  text-align: center;
  pointer-events: none;
  
}

.dashboard .number-box .text {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin-top: 3.5em;
  text-transform: uppercase;
  text-align: center;
  width: 100%;
}

.dashboard.manager .number-box {
  height: calc(20vh - 15px);
}

.dashboard.manager .number-box .number,
.dashboard.manager .number-box .text {
  top: 42%;
}

.chart-container {
  border-radius: 0.428571429em;
  background: #fff;
  padding: 1rem;
  height: 40vh;
  margin-bottom: 30px;
}

.chart-container .header  {
  padding-left: 0.75rem;
  padding-bottom: 0.7rem;
  border-bottom: 1px solid var(--table-border-color);
}

.chart-container .header h3  {
  text-transform: uppercase;
  font-size: 1.2em;
  display: inline-block;
  color : var(--dashboard-block-title-color);
}

.chart-container .header .form-control {
  float: right;
  width: 10em;
  margin-top: -0.4em;
}

.chart-container select {
  font-size: 1em;
}

.chart-container .recharts-responsive-container {
  padding: 2em 0;
}

.chart-container.pie-chart .recharts-responsive-container {
  padding: 0;
}

.chart-container.pie-chart .recharts-legend-item {
  margin-bottom: 0.5em;
}

.chart-container.pie-chart .recharts-legend-item:last-child {
  margin-bottom: 0;
}

.chart-container.pie-chart .recharts-legend-item-text {
  padding-left: 0.5em;
}

.recharts-pie-labels path {
  stroke: var(--primary-color);
}

.recharts-text.recharts-pie-label-text {
  fill: var(--primary-color);
}

.dashboard .delete-button {
  position: absolute;
  top: 0.4em;
  right: 0;
  width: 2rem;
  height: 2rem;
  border: 0;
}

.dashboard .delete-button > div {
  position: absolute;
  left: 0.6em;
  top: 1.1em;
  width: 1rem;
  height: 0.1em;
  background: var(--primary-color);
  transform: rotate(45deg);
  transform-origin: center;
}

.dashboard .delete-button > div:last-child {
  transform: rotate(-45deg);
}
.dashboard .back-button {
  padding-left: 2.7em;
  position: relative;
}
.dashboard .back-button::before {
  content : "";
  position: absolute;
  width: 25px;
  height: 12px;
  top:0.2em;
  left:0;
  background-color: var(--primary-color);
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size:25px;
  mask-image: url('../../img/icon-arrow-left.svg');
  background-size: 100%;
  cursor: pointer;
}
@media (min-width: 960px) {

  .dashboard-header .user {
    font-size: 1em;
  }

  .dashboard-menu a {
    font-size: 1.1em;
  }

}

@media (min-width: 1250px) {

  section.primary.dashboard {
    padding-top: 0;
  }

  section.primary.dashboard > .container.dashboard-header {
    position: relative;
    height: auto;
    width: auto;
    left: auto;
    top: auto;
    padding-bottom: 3em;
  }

}


@media (min-width: 1440px) {

  .dashboard-menu a {
    margin: 0 1em;
  }
  
}


