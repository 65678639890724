.button-container
{
    position: relative;
    display: inline-block;

}
.button-preloader
{
    position: absolute;
    width: 100%;
    height: 100%;
}
.button-container .preloader-container
{
    transform: scale(0.8);
}
.button-text {visibility: visible}
.button-preloader {visibility: hidden}
.is-loading .button-text {visibility: hidden;}
.is-loading .button-preloader {visibility: visible;}

