.fav-wrap {
  position: relative;
  width: 25em;
  min-height: 100vh;
  margin-left: 5em;  
  border-left: 1px solid var(--primary-border-color);
  padding: 1em 0 0 0;
}

.fav-wrap .inner {
  padding: 0 2em;
  height: 100%;
}
.fav-wrap .content
{
  width: 100%;
  height: 100%;
}
.fav-wrap section {
  margin-top: 1em;
  padding-top: 1em;
  border-top: 1px solid var(--primary-border-color);
  min-height: 10em;
}

.fav-wrap h2 {
  margin: 1em 0 0 0;
  padding: 2em 0 0 0;
  position:relative;
  font-weight: bold;
}

.fav-wrap h2:before {
  content:"";
  position: absolute;
  width:100%;
  height: 1.5em;
  top: 0;
  mask-image: url('../img/icon-favorites.svg');
  background-color: var(--primary-color);
  mask-repeat: no-repeat;
  mask-size: 1.5em;

}

.fav-wrap h3 {
  font-size: 1.2em;
  margin-bottom: 1rem;
}

.no-fav-container
{
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  left: calc(50% - 2rem);
  top: calc(50% - 2rem);
}
.no-fav
{

  position: relative;
  display: block;
  width:3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  border : 1px solid var(--primary-color);
  margin-bottom: 0.4rem;

}
.no-fav:before
{
  content: '';
  position: absolute;
  left:0.8rem;
  top:0.9rem;
  width: 1.7rem;
  height: 1.7rem;
  background-color: var(--primary-color);
  mask-repeat: no-repeat;
  mask-position: center;
  mask-image: url('../img/icon-like.svg');
}

