.simple-alert
{
    background: rgba(0,0,0,0.8);
    color:#fff;
    padding: 0.5rem 1rem;
    border-radius: 5rem;
    position: fixed;
    left:-999px;
    top:2.5rem;
    z-index: 1003;
    opacity: 0;
    transition: opacity 0.5s, left 0.5s;
}
.simple-alert.show
{
    left:2.5rem;
    opacity: 1;
}
.simple-alert.hide
{
    left:5rem;
    opacity: 0;
}