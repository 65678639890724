.dashboard-box.client-profile {
    height: 25vh;
}

.dashboard-box.client-profile .header {
    border-bottom: 1px solid var(--table-border-color);
}

.dashboard-box.client-profile h3 {
    display: inline-block;
    text-transform: none;
    border: 0;
    color : var(--dashboard-block-title-color);

}

.dashboard-box.client-profile .row {
    padding: 0.7em 0;
    margin: 0;
}

.dashboard-box.client-profile .row:nth-child(2),.dashboard-box.client-profile .row:nth-child(3) {
    border-bottom: 1px solid var(--table-border-color);
}

.dashboard-box.client-profile .item div div:first-child {
    font-weight: 600;
}

.edit-button {
    float: right;
    text-transform: uppercase;
    font-weight: 600;
    margin-top: 0.1em;
    cursor: pointer;
}

.dashboard-table.actions-history {
    height: 30vh;
}

.dashboard-table.actions-history tbody {
    max-height: calc(30vh - 8em);
}

.dashboard-table.favourite-properties {
    height: 25vh;
}

.dashboard-table.favourite-properties tbody {
    max-height: calc(25vh - 8em);
}


.dashboard-table.client-notes {
    height: 25vh;
}

.dashboard-table.client-notes tbody {
    max-height: calc(25vh - 8em);
}

.dashboard-table.property-views {
    height: calc(55vh + 30px);
}

.dashboard-table.property-views tbody {
    max-height: calc(55vh - 8em + 30px);
}

.dashboard-table.presentations {
    height: calc(55vh + 30px);
}

.dashboard-table.presentations tbody {
    max-height: calc(55vh - 8em + 30px);
}