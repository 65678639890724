.fav-button
{
    position: absolute;
    top: 2.5em;
    right: 2.5em;
    width:3.5em;
    height: 3.5em;
    border: 1px solid var(--primary-color);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.icon-like
{
    width:1.5rem
}

.icon-like:before
{
    content: '';
    position: absolute;
    left:0.8em;
    top:0.9em;
    width: 1.7em;
    height: 1.7em;
    background-color: var(--primary-color);
    mask-repeat: no-repeat;
    mask-position: center;
    mask-image: url('../img/icon-like.svg');
}
.fav-button:hover, .fav-button.is-fav
{
    background: var(--primary-color);
}
.fav-button:hover .icon-like:before, .fav-button.is-fav .icon-like:before
{
    background: url('../img/icon-like-light.svg') no-repeat center;
}