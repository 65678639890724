.filter-wrap {
  position: relative;
  width: 25em;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  margin-left: 5em;  
  border-left: 1px solid var(--primary-border-color);
  padding: 1em 0 0 0;
}

.filter-wrap .inner {
  padding: 0 2em;
}

.filter-wrap section {
  margin-top: 1em;
  padding-top: 1em;
  border-top: 1px solid var(--primary-border-color);
  min-height: 10em;
}

.filter-wrap h2 {
  margin: 1em 0 0 0;  
  padding: 2em 0 0 0;
  position:relative;
  font-weight: bold;
}

.filter-wrap h2:before {
  content:"";
  position: absolute;
  width:100%;
  height: 1.5em;
  top: 0;
  -webkit-mask-image: url('../img/icon-filter.svg');
  mask-image: url('../img/icon-filter.svg');
  background-color: var(--primary-color);
  mask-repeat: no-repeat;
  mask-size: 1.5em;

}

.filter-wrap h3 {
  font-size: 1.2em;
  margin-bottom: 1rem;
}

.filter-wrap .button-view {
  position: relative;
  padding-left: 2.5em;
  height: 2em;
  line-height: 2em;
  margin-left: 1em;
  color : #333;
}
.filter-wrap .button-view.map
{
  margin-left: 0em;
}
.filter-wrap .button-view:nth-child(2) {
  margin-left: 0;
}

.filter-wrap .button-view.grid {
  margin-top: 1em;
}
.filter-wrap .button-view {

}
.filter-wrap .button-view span {

  position: absolute;
  left: 0;
  top: 0;
  width: 2em;
  height: 2em;
  border: 1px solid var(--primary-color);
  border-radius: 2em;

}
.filter-wrap .button-view:hover span, .filter-wrap .button-view.active span
{
  background: var(--primary-color);
}
.filter-wrap .button-view span:before {
  content : "";
  position: absolute;
  left: -1px;
  top: -1px;
  width: 2em;
  height: 2em;
  background: var(--primary-color);
  border-radius: 2em;
  mask-repeat: no-repeat;
  mask-position: center;
}
.filter-wrap .button-view:hover span:before,.filter-wrap .button-view.active span:before {
  background: white;
}
.filter-wrap .button-view.map span:before {
  mask-image: url('../img/icon-map-dark.svg');
}

.filter-wrap .button-view.grid span:before {
  mask-image: url('../img/icon-grid-dark.svg');
}

.filter-wrap .button-view.list span:before {
  mask-image: url('../img/icon-list-dark.svg');
}

.filter-wrap .button-view.map:hover span:before, .filter-wrap .button-view.map.active span:before {
  mask-image: url('../img/icon-map-light.svg');
}

.filter-wrap .button-view.grid:hover span:before, .button-view.grid.active span:before {
  mask-image: url('../img/icon-grid-light.svg');
}

.filter-wrap .button-view.list:hover span:before, .button-view.list.active span:before {
  mask-image: url('../img/icon-list-light.svg');
}

.filter-wrap  .form-group {
  padding-left: 1em;
}

.filter-wrap .form-group:first-child {
  padding-left: 0;
}

section .price-range {
  position: relative;
}

.filter-wrap input[type=range] {
  width: 100%;
  position: absolute;
  left: 0;
  top: 7em;
  pointer-events: none;
  -webkit-appearance: none;
  outline: none;
  background-color: transparent;
  background-color: var(--primary-border-color);
  border-color: transparent;
  border-radius: 50%;
  height: 1px;
}

.filter-wrap input[type=range]::-webkit-slider-thumb {
  pointer-events:auto;
  -webkit-appearance: none;
  background-color: var(--primary-color);
  border-radius: 50%;
  width: 1.1rem;
  height: 1.1rem;
}

#filter-price-range-max {
  background-color: transparent;
}

.price-display {
  margin-top: 1em;
}

form.floor-type .form-group {
  flex-basis: 100%;
}

.button-clear {
  position: absolute;
  right: 2em;
  bottom: 2em;
  padding: 1em 2.8em;
  font-weight: normal;
  background: transparent;
  color: red;
  border: 1px solid red;
}

.form-group
{
  margin-bottom:0.3rem;
  padding: 0.1rem 0 0 0;
}