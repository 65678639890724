.dashboard-box.property-info-box {
    position: relative;
    height: 17.5vh;
    font-size: 1em;
}

.property-info-box.dashboard-property-title {
    height: calc(35vh + 30px);
}

.dashboard-property-title h3 {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border: 0;
    margin: 0;
    padding: 0;
    font-size: 3.4em;
    font-weight: normal;
}

.property-info-box .content {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border: 0;
    margin: 0;
    padding: 0;
    font-size: 2em;
}

section.dashboard.property .number-box {
    height: 20vh;
}

.dashboard-table.views-by-client,
.dashboard-table.favs-by-client {
    height: calc(40vh + 30px);
}