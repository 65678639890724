.aws-btn {

    --slider-height-percentage: 60%;
    --slider-transition-duration: 700ms;
    --organic-arrow-thickness: 4px;
    --organic-arrow-border-radius: 0px;
    --organic-arrow-height: 40px;
    --organic-arrow-color: #ffffff;
    --control-button-width: 10%;
    --control-button-height: 25%;
    --control-button-background: transparent;
    --control-bullet-color: var(--primary-color);
    --control-bullet-active-color: #26456f;
    --control-button-opacity : .8;
    --loader-bar-color: var(--primary-color);
    --loader-bar-height: 3px;
}


.active-slide
{
    position: absolute;
    left: 3rem;
    bottom: 3rem;
    font-weight: bold;
    font-size:1.2rem;
    color:#fff;
    z-index: 100;
}

.caption
{
    position: absolute;
    left: 3rem;
    top: 3rem;
    font-weight: bold;
    font-size:2rem;
    color:#fff;
    z-index: 100;
}
.caption.dark {
    color:#000
}
