.sub-user-menu
{
    border-top: 1px solid var(--primary-border-color);
    padding: 2rem;
}
.sub-user-menu-icon {
    background-color: var(--primary-color);
    mask-repeat: no-repeat;
    mask-position: center;
    width: 2.5em;
    mask-image: url('../img/icon-sales.svg');
}
.user-menu-role{ font-size: 1rem}
.user-menu-name{ font-size: 1.1rem; font-weight: bold}