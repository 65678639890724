.lightbox {
    position: fixed;
    background: #f0f0f0;
    top:0;
    left:0;
    width:calc(100vw - 5em);
    height: 100vh;
    z-index: 100;
    opacity: 0;
    transition: opacity .2s .1s;
}
.main-menu-is-open .lightbox {
    width:calc(100vw - 30em);
}
.lightbox.lightbox--opened {
    opacity: 1;
}
.lightbox__image-container {
    width:100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.lightbox__image {
    width: 80%;
    height: 80%;
    position: relative;

}
.main-menu-is-open .lightbox__image {
    width: 60%;
    height: 60%;
}
.lightbox__image img {
    position: absolute;
}

