.dashboard-box.sales-manager-profile {
    height: 17vh;
}

.dashboard-box.sales-manager-profile .header {
    border-bottom: 1px solid var(--table-border-color);
}

.dashboard-box.sales-manager-profile h3 {
    display: inline-block;
    text-transform: none;
    border: 0;

}

.dashboard-box.sales-manager-profile .row {
    padding: 1.7em 0;
    margin: 0;
}


.dashboard-box.sales-manager-profile .item div div:first-child {
    font-weight: 600;
}

.edit-button {
    float: right;
    text-transform: uppercase;
    font-weight: 600;
    margin-top: 0.1em;
    cursor: pointer;
}

section.sales-manager .dashboard-table.sales-reps {
    height: 40vh;
}

section.sales-manager .dashboard-table.sales-reps tbody {
    max-height: calc(40vh - 8em);
}

section.sales-manager .dashboard-table.property-views {
    height: calc(31.5vh - 15px);
}

section.sales-manager .dashboard-table.property-views tbody {
    max-height: calc(31.5vh - 8em);
}

section.sales-manager .dashboard-table.sales-rep-property-actions {
    height: calc(31.5vh - 15px);
}

section.sales-manager .dashboard-table.sales-rep-property-actions tbody {
    max-height: calc(31.5vh - 8em);
}


