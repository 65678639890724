.viewer360 {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.viewer360.fullscreen {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
}

.viewer360 iframe {
    width: 100%;
    height: 100%;
}