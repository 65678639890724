.list-view {
  padding: 2rem;
  position: relative;

}
.list-view:not(.loading)
{
  height: auto !important;
}


.list-view .inner {
  background: #fff;
}

.list-view table tr {cursor:pointer}

.table .thead-dark th {
  color: #fff;
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.list-view .property-status-icon {
  position: relative;
  display: inline-block;
  margin-left: 1rem;

  width: 0.6rem;
  height: 0.6rem;
  border-radius: 50%;

}

.list-view .property-status-icon.available {
  background: var(--available-color);
}

.list-view .property-status-icon.sold {
  background: var(--sold-color);
}
.list-view .property-status-icon.reserved {
  background: var(--option-color);
}
.list-view .property-status-icon.not_released {
  background: var(--not-released-color);
}