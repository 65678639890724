section.properties .dashboard-table.properties {
    height: 80vh;
    margin-bottom: 0;
}

section.properties .dashboard-table.properties tbody {
    max-height: calc(80vh - 8em);
}

section.properties .number-box {
    height: calc(20vh - 22.5px);
}

section.properties .dashboard-table.properties tbody td:nth-child(4),
section.properties .dashboard-table.properties tbody td:nth-child(5) {
    cursor: pointer;
}