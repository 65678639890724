section.property-actions .number-box {
    height: calc((80vh - 60px) / 3);
}

section.property-actions .dashboard-table.property-actions {
    height: 80vh;
    margin-bottom: 0;
}

section.property-actions .dashboard-table.property-actions tbody {
    max-height: calc(80vh - 8em);
}