.fav-list
{
    margin-top:1.5rem;
    margin-bottom: 1.5rem;
    max-height: calc(100vh - 20rem);
    overflow: scroll;
}
.button-delete {

    padding: 1em 2.8em;
    font-weight: normal;
    background: transparent;
    color: red;
    border: 1px solid red;
}