.submenu-wrap {
    position: relative;
    width: 25em;
    min-height: 100vh;
    min-height: -webkit-fill-available;
    margin-left: 5em;
    border-left: 1px solid var(--primary-border-color);

}
.submenu-wrap .inner {
    display: flex;
    align-items: center;
    min-height: 100vh;
    min-height: -webkit-fill-available;
}
.submenu
{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    max-height: 70vh;
    overflow: auto;
}
.submenu-item
{
    display: block;
    width: 100%;
    padding: 1.2em;
    text-transform: uppercase;
    border-top : 1px solid var(--primary-border-color);
    color: #212529;
    font-size: 1.2em;
    line-height: 1.2em;

}

.submenu-item:visited {
    color: #212529;
}

.submenu-item:hover {
    color: var(--primary-color);
}

.submenu-item.last
{
    border-bottom: 1px solid var(--primary-border-color);
}

