.loading-overlay
{
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    background: rgba(255,255,255,0.8);
    width: 100%;
    height: 100vh;
    z-index: 101;
    top: 0;
    left: 0;
    transition: opacity .3s, visibility .3s;
}
.hide-loading-overlay
{
    opacity: 0;
    visibility: hidden;
}
